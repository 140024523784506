<template>
  <div class="all-settings">
    <v-card>
      <v-text-field
        class="document-tabs-filter ma-2"
        flat
        clearable
        dense
        hide-details
        :label="$t('t.Settings')"
        solo
        v-model="filterText"
        :prepend-inner-icon="$icon('i.Search')"
      />
      <v-list>
        <v-list-item
          v-for="(item, idx) in computedItems"
          :key="idx"
          link
          @click.stop="goToItem(item)"
        >
          <!-- <v-icon class="mr-4">{{action.icon}}</v-icon> -->

          <v-list-item-content>
            <span>{{item.title}}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <main>
      <div
        v-for="(item, idx) in computedItems"
        :key="idx + item.title"
      >
        <component
          v-if="!isSettingsV5(item)"
          :is="componentName(item)"
          :ref="item.id"
        />
        <v-card
          v-else
          class="setting-v5"
          :ref="item.id"
        >
          <v5-settings />
        </v-card>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {
    AgingBuckets: () => import('./aging-buckets'),
    AuthenticationRules: () => import('./authentication-rules'),
    ClickToDial: () => import('./click-to-dial'),
    CreditInsurance: () => import('./credit-insurance'),
    EcosystemAuthorizations: () => import('./ecosystem-authorizations'),
    EscalationProtocol: () => import('./escalation-protocol'),
    LetterDematerialized: () => import('./letter-dematerialized'),
    License: () => import('./license'),
    V5Settings: () => import('../settings')
  },
  computed: {
    computedItems () {
      let items = this.items
      items = items.filter(i => {
        return i.adminLevel === 'master-admin'
          ? this.userIsMasterAdmin
          : i.adminLevel === 'admin'
            ? this.userIsAdmin
            : true
      })

      items = items.filter(_ => {
        return this.filterText ? _.title.toLowerCase().includes(this.filterText.toLowerCase()) : true
      })

      return items.sort((a, b) => a.sortTitle.toLowerCase() < b.sortTitle.toLowerCase() ? -1 : 0)
    },
    userIsAdmin () {
      return this.$store.getters.isAdmin()
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    }
  },
  methods: {
    isSettingsV5 (item) {
      return item.id === 'v5-settings'
    },
    componentName (item) {
      return item.id
    },
    goToItem (item) {
      this.filterText = null
      this.$refs[item.id][0].$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  },
  data () {
    return {
      filterText: null,
      items: [
        // { id: 'aging-buckets', title: this.$t('t.AgingBuckets'), sortTitle: this.$t('t.AgingBuckets'), needAdmin: true },
        //  { id: 'escalation-protocol', title: this.$t('t.EscalationProtocol'), sortTitle: this.$t('t.EscalationProtocol'), needAdmin: true },

        {
          id: 'ecosystem-authorizations',
          title: this.$t('t.EcosystemAuthorizations'),
          sortTitle: this.$t('t.EcosystemAuthorizations'),
          adminLevel: 'master-admin'
        },
        {
          id: 'license',
          title: this.$t('t.License'),
          sortTitle: this.$t('t.License')
          // adminLevel: 'admin'
        },
        {
          id: 'credit-insurance',
          title: this.$t('t.CreditInsurance'),
          sortTitle: this.$t('t.CreditInsurance')
          // adminLevel: 'admin'
        },
        {
          id: 'authentication-rules',
          title: this.$t('t.AuthenticationRules'),
          sortTitle: this.$t('t.AuthenticationRules'),
          adminLevel: 'admin'
        },
        {
          id: 'letter-dematerialized',
          title: this.$t('t.LetterDematerialized'),
          sortTitle: this.$t('t.LetterDematerialized'),
          adminLevel: 'master-admin'
        },
        {
          id: 'click-to-dial',
          title: this.$t('t.ClickToDial'),
          sortTitle: this.$t('t.ClickToDial')
          // adminLevel: 'admin'
        },
        {
          id: 'v5-settings',
          title: this.$t('t.Others'),
          sortTitle: 'zzzzz' // To keep at the end from sorting
          // adminLevel: 'admin'
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
.all-settings
  display grid
  grid-template-columns auto 1fr
  flex auto

.all-settings main
  display flex
  gap 1rem
  flex-direction column
  grid-column 2
  overflow-y auto

.setting-v5
  overflow hidden
  height 90vh
  min-height 50vh
</style>
